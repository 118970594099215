import React from 'react'
import { Col, Row } from 'react-bootstrap';
import './Testimonial.css'

function Testimonial() {
  
  return (
   <>
   <div className="container-fluid">
   <h1 className="text-center  mb-4 fw-bold" style={{ color: '#6f00ff' }}>TESTIMONIALS</h1>
    <Row>
     
      <Col className="col-lg-6 col-md-12 col-sm-12 className='d-flex justify-content-center align-items-center'">
      <div className="card extra-effect mb-4 ms-4" style={{width: '30rem',borderRadius:'14px' ,border:'2px solid #6f00ff' }}>
  
  <div class="card-body ">
  <img src="https://randomuser.me/api/portraits/men/1.jpg" className="rounded-circle" alt="Muhammad Shahzaib" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
    <p>Some quick example text to build on the card title and make up the bulk of the card's content.
    Some quick example text to build on the card title and make up the bulk of the card's content.
    Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    <h3 className="text-end">Sarah Khan</h3>
    <p className="text-end">Ceo Techshape Solutions</p>
  </div>
</div>
      </Col>
      <Col className="col-lg-6 col-md-12 col-sm-12 className='d-flex justify-content-center align-items-center'">
      <div className="card extra-effect " style={{width: '30rem',borderRadius:'14px' ,border:'2px solid #6f00ff' }}>
  
  <div class="card-body ">
  <img src="https://randomuser.me/api/portraits/men/1.jpg" className="rounded-circle" alt="Muhammad Shahzaib" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
    <p>Some quick example text to build on the card title and make up the bulk of the card's content.
    Some quick example text to build on the card title and make up the bulk of the card's content.
    Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    <h3 className="text-end">Sarah Khan</h3>
    <p className="text-end">Ceo Techshape Solutions</p>
  </div>
</div>
      </Col>
      <Col className="col-lg-6 col-md-12 col-sm-12 className='d-flex justify-content-center align-items-center'">
      <div className="card extra-effect ms-4" style={{width: '30rem',borderRadius:'14px' ,border:'2px solid #6f00ff' }}>
  
  <div class="card-body ">
  <img src="https://randomuser.me/api/portraits/men/1.jpg" className="rounded-circle" alt="Muhammad Shahzaib" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
    <p>Some quick example text to build on the card title and make up the bulk of the card's content.
    Some quick example text to build on the card title and make up the bulk of the card's content.
    Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    <h3 className="text-end">Sarah Khan</h3>
    <p className="text-end">Ceo Techshape Solutions</p>
  </div>
</div>
      </Col>
      <Col className="col-lg-6 col-md-12 col-sm-12 className='d-flex justify-content-center align-items-center'">
      <div className="card extra-effect" style={{width: '30rem',borderRadius:'14px' ,border:'2px solid #6f00ff' }}>
  
  <div class="card-body ">
  <img src="https://randomuser.me/api/portraits/men/1.jpg" className="rounded-circle" alt="Muhammad Shahzaib" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
    <p>Some quick example text to build on the card title and make up the bulk of the card's content.
    Some quick example text to build on the card title and make up the bulk of the card's content.
    Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    <h3 className="text-end">Sarah Khan</h3>
    <p className="text-end">Ceo Techshape Solutions</p>
  </div>
</div>
      </Col>
    </Row>
   </div>
   </>
  )
}

export default Testimonial