import React, { useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function AdsTeam() {
  const adContainerRef = useRef(null);

  const handleLoadAds = () => {
    // Inject first ad script
    const firstScriptContent = document.createElement('script');
    firstScriptContent.type = 'text/javascript';
    firstScriptContent.innerHTML = `
      atOptions = {
        'key': 'cc2ecb56a48d91c516eed1b334261d85',
        'format': 'iframe',
        'height': 90,
        'width': 728,
        'params': {}
      };
    `;
    document.body.appendChild(firstScriptContent);

    const firstScript = document.createElement('script');
    firstScript.type = 'text/javascript';
    firstScript.src = "//www.topcreativeformat.com/cc2ecb56a48d91c516eed1b334261d85/invoke.js";
    firstScript.async = true;
    document.body.appendChild(firstScript);

    firstScript.onload = () => {
      const iframe = document.querySelector('iframe');
      if (iframe) {
        iframe.contentWindow.document.body.click();
      }
    };

    // Inject second ad script
    const secondScriptContent = document.createElement('script');
    secondScriptContent.type = 'text/javascript';
    secondScriptContent.innerHTML = `
      atOptions = {
        'key' : '0e97942bdbc6aa6694b7027a9a441687',
        'format' : 'iframe',
        'height' : 300,
        'width' : 160,
        'params' : {}
      };
    `;
    document.body.appendChild(secondScriptContent);

    const secondScript = document.createElement('script');
    secondScript.type = 'text/javascript';
    secondScript.src = "//www.topcreativeformat.com/0e97942bdbc6aa6694b7027a9a441687/invoke.js";
    secondScript.async = true;
    document.body.appendChild(secondScript);

    secondScript.onload = () => {
      const iframe = document.querySelector('iframe');
      if (iframe) {
        iframe.contentWindow.document.body.click();
      }
    };
  };

  return (
    <>
      <div className="container-fluid" style={{ backgroundColor: '#f8f9fa' }}>
        <h1 className='text-center mb-4 mt-4 fw-bold' style={{ color: '#6f00ff' }}>AdsTeam</h1>
        <div ref={adContainerRef} className='text-center mb-4' id="unique-ad-container" style={{ backgroundColor: '#333', padding: '20px' }}>
          {/* Ad will be injected here */}
        </div>
        <div className="text-center mb-4">
          <Button onClick={handleLoadAds} className="btn" id="load-ads-button" style={{ width: '200px', backgroundColor: '#5d21d2' }}>
            Load Ads
          </Button>
        </div>
        <div className='row justify-content-center align-items-center'>
          <div className='col-lg-6 col-md-6 col-sm-12'>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicNameUnique">
                <Form.Label>Full Name</Form.Label>
                <Form.Control type="text" placeholder="Enter name" style={{ backgroundColor: 'rgb(235, 235, 235)' }} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmailUnique">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" placeholder="Enter email" style={{ backgroundColor: 'rgb(235, 235, 235)' }} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formServiceSelectUnique">
                <Form.Label>Select Service</Form.Label>
                <Form.Select aria-label="Default select example" style={{ backgroundColor: 'rgb(235, 235, 235)' }}>
                  <option>Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formTextareaUnique">
                <Form.Label>Example textarea</Form.Label>
                <Form.Control as="textarea" rows="3" style={{ backgroundColor: 'rgb(235, 235, 235)' }} />
              </Form.Group>
              <div className="text-center mb-4">
                <Button className="btn" type="submit" id="submit-button" style={{ width: '550px', backgroundColor: '#5d21d2' }}>
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdsTeam;
