import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../../images/logo.jpeg';
import './Header.css';

function Header() {
  return (
    <Navbar expand="lg" style={{ backgroundColor: 'black' }} variant="dark">
      <Container>
        <Navbar.Brand href="#home">
          <img src={logo} alt="Logo" style={{ width: '50px', height: '50px' }} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            <Nav.Link href="#home" style={{ color: 'white' }}>Home</Nav.Link>
            <Nav.Link href="#about" style={{ color: 'white' }}>About Us</Nav.Link>
            <Nav.Link href="#services" style={{ color: 'white' }}>Services</Nav.Link>
            <Nav.Link href="#team" style={{ color: 'white' }}>Our Team</Nav.Link>
            <Nav.Link href="#contact" style={{ color: 'white' }}>Contact Us</Nav.Link>
          </Nav>
          <form className="d-flex">
            <button className="btn btn-style" type="submit">Contact Us</button>
          </form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
