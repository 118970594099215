import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import './Services.css'

function Services()
{
  const [selectedCard, setSelectedCard] = useState(null);

  const handleCardClick = (cardId) =>
  {
    setSelectedCard(cardId);
  };

  const cardData = [
    {
      id: 1,
      title: 'Mobile App Development Services',
      text: 'Explore our stellar mobile app development services, including native, hybrid, and cross-platform solutions, designed to bring your vision to life.',
      imageUrl: 'https://images.unsplash.com/photo-1511707171634-5f897ff02aa9'
    },
    {
      id: 2,
      title: 'Artificial Intelligence Development Services',
      text: 'Leverage our AI development services to integrate smart solutions into your business processes and drive innovation.',
      imageUrl: 'https://images.unsplash.com/photo-1518770660439-4636190af475'
    },
    {
      id: 3,
      title: 'UI/UX Design Services',
      text: 'Transform your ideas into intuitive and engaging designs with our expert UI/UX services.',
      imageUrl: 'https://images.unsplash.com/photo-1498050108023-c5249f4df085'
    },
    {
      id: 4,
      title: 'Custom Web App Development Services',
      text: 'Unlock a wealth of web solutions with our custom web app development services, from SaaS to single-page applications.',
      imageUrl: 'https://images.unsplash.com/photo-1551446591-142875a901a1'
    },
    {
      id: 5,
      title: 'Artificial Intelligence',
      text: 'Unlock a wealth of web solutions with our custom web app development services, from SaaS to single-page applications.',
      imageUrl: 'https://images.unsplash.com/photo-1551446591-142875a901a1'
    },
    {
      id: 6,
      title: 'Game Development',
      text: 'Unlock a wealth of web solutions with our custom web app development services, from SaaS to single-page applications.',
      imageUrl: 'https://images.unsplash.com/photo-1551446591-142875a901a1'
    },
    {
      id: 7,
      title: 'Sales&Marketing',
      text: 'Unlock a wealth of web solutions with our custom web app development services, from SaaS to single-page applications.',
      imageUrl: 'https://images.unsplash.com/photo-1551446591-142875a901a1'
    },
    {
      id: 8,
      title: 'Quality Assurance',
      text: 'Unlock a wealth of web solutions with our custom web app development services, from SaaS to single-page applications.',
      imageUrl: 'https://images.unsplash.com/photo-1551446591-142875a901a1'
    },
  ];

  return (
    <div className="container-fluid">
      <h1 className="text-center  mb-4 fw-bold" style={{ color: '#6f00ff' }}>Our Services</h1>
      <div className="row ">
        {cardData.map((card) => (
          <div key={card.id} className="col-lg-3 col-md-6 col-sm-12 d-flex align-items-stretch">
            <Card
              className="mb-4 further-info"
              style={{
                width: '100%',
                backgroundColor: selectedCard === card.id ? '#e0cfff' : '#fff',
                cursor: 'pointer',borderRadius:'25px' 
              }}
              onClick={() => handleCardClick(card.id)}
            >
             
              
              <Card.Img 
                variant="top"
                src={card.imageUrl}
                style={{ height: '350px',borderRadius:'25px' }}
                
              />
              <Card.ImgOverlay className="align-self-end">
              <Card.Title className="text-white fw-bold ">{card.id}</Card.Title>
                <Card.Title className="text-white fw-bold">{card.title}</Card.Title>
              </Card.ImgOverlay>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Services;
