import React from 'react';

function Team(){
  return (
    <>
    <div className="container-fluid"  style={{ backgroundColor: '#f8f9fa' }}>

    <h1 className="text-center mt-4 fw-bold" style={{color:'#6f00ff'}}>Our Team</h1>
   <p className="text-center"> Which is the same as saying through shrinking from toil and perfectly simple and <br/>easy to distinguish.</p>
      <div className="row justify-content-center">
        <div className="col-lg-3 col-md-12 col-sm-12 d-flex flex-wrap justify-content-center">
          <div className="team-member mx-4 my-2 text-center">
            <img src="https://randomuser.me/api/portraits/men/1.jpg" className="rounded-circle" alt="Muhammad Shahzaib" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
            <p>Muhammad Shahzaib (CEO)</p>
          </div>
          </div>
          <div className="col-lg-3 col-md-12 col-sm-12 d-flex flex-wrap justify-content-center">

          <div className="team-member mx-4 my-2 text-center">
            <img src="https://randomuser.me/api/portraits/men/2.jpg" className="rounded-circle" alt="Muhammad Sameer" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
            <p>Muhammad Sameer (CTO)</p>
          </div>
          </div>
          <div className="col-lg-3 col-md-12 col-sm-12 d-flex flex-wrap justify-content-center">

          <div className="team-member mx-4 my-2 text-center">
            <img src="https://randomuser.me/api/portraits/men/3.jpg" className="rounded-circle" alt="Ahsan Hameed" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
            <p>Ahsan Hameed (CPO)</p>
          </div>
</div>
<div className="col-lg-3 col-md-12 col-sm-12 d-flex flex-wrap justify-content-center">
          <div className="team-member mx-4 my-2 text-center">
            <img src="https://randomuser.me/api/portraits/men/4.jpg" className="rounded-circle" alt="Sunday Oyeyemi" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
            <p>Sunday Oyeyemi (Backend Developer)</p>
          </div>
          </div>
          </div>
          <div className="row justify-content-center">
        <div className="col-lg-3 col-md-12 col-sm-12 d-flex flex-wrap justify-content-center">
          <div className="team-member mx-4 my-2 text-center">
            <img src="https://randomuser.me/api/portraits/men/5.jpg" className="rounded-circle" alt="Muneeb Islam" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
            <p>Muneeb Islam (Senior Frontend Developer)</p>
          </div>
          </div>
          <div className="col-lg-3 col-md-12 col-sm-12 d-flex flex-wrap justify-content-center">
          <div className="team-member mx-4 my-2 text-center">
            <img src="https://randomuser.me/api/portraits/women/6.jpg" className="rounded-circle" alt="Tayyaba Khaksar" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
            <p>Tayyaba Haqsar (Junior Frontend Developer)</p>
          </div>
</div>
    <div className="col-lg-3 col-md-12 col-sm-12 d-flex flex-wrap justify-content-center">
          <div className="team-member mx-4 my-2 text-center">
            <img src="https://randomuser.me/api/portraits/men/7.jpg" className="rounded-circle" alt="Muhammad Sharjeel" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
            <p>Muhammad Sharjeel (Junior Backend Developer)</p>
          </div>
</div>
<div className="col-lg-3 col-md-12 col-sm-12 d-flex flex-wrap justify-content-center">
          <div className="team-member mx-4 my-2 text-center">
            <img src="https://randomuser.me/api/portraits/women/8.jpg" className="rounded-circle" alt="Qainat Arshad" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
            <p>Qainat Arshad (Frontend Intern)</p>
          </div>
          </div>
          </div>
        </div>
          </> 
    
  );
}

export default Team;
