import React, { useEffect, useRef } from 'react';

function AdsFooter() {
  const footerRef = useRef(null);

  useEffect(() => {
    // Scroll to the footer element when the component is mounted
    footerRef.current.scrollIntoView({ behavior: 'smooth' });
    handleClick();
  }, []);

  const handleClick = () => {
    // Provided Ad Script
    const adScript1 = document.createElement('script');
    adScript1.async = true;
    adScript1.setAttribute('data-cfasync', 'false');
    adScript1.src = "//pl23601761.highrevenuenetwork.com/24e5ab5fc601520cfdc6542fdc07ee53/invoke.js";
    document.getElementById('container-24e5ab5fc601520cfdc6542fdc07ee53').appendChild(adScript1);

    // First Script
    const firstScriptContent = document.createElement('script');
    firstScriptContent.type = 'text/javascript';
    firstScriptContent.innerHTML = `
      atOptions = {
        'key': 'cc2ecb56a48d91c516eed1b334261d85',
        'format': 'iframe',
        'height': 90,
        'width': 728,
        'params': {}
      };
    `;
    document.body.appendChild(firstScriptContent);

    const firstScript = document.createElement('script');
    firstScript.type = 'text/javascript';
    firstScript.src = "//www.topcreativeformat.com/cc2ecb56a48d91c516eed1b334261d85/invoke.js";
    firstScript.async = true;
    document.body.appendChild(firstScript);

    // // Second Script
    // const secondScriptContent = document.createElement('script');
    // secondScriptContent.type = 'text/javascript';
    // secondScriptContent.innerHTML = `
    //   atOptions = {
    //     'key': '227dcf5df8ea46fc431547ecedec059b',
    //     'format': 'iframe',
    //     'height': 60,
    //     'width': 468,
    //     'params': {}
    //   };
    // `;
    // document.body.appendChild(secondScriptContent);

    // const secondScript = document.createElement('script');
    // secondScript.type = 'text/javascript';
    // secondScript.src = "//www.topcreativeformat.com/227dcf5df8ea46fc431547ecedec059b/invoke.js";
    // secondScript.async = true;
    // document.body.appendChild(secondScript);

    // Simulate click on the created elements if needed
    firstScript.onload = () => {
      const iframe = document.querySelector('iframe');
      if (iframe) {
        iframe.contentWindow.document.body.click();
      }
    };

    // secondScript.onload = () => {
    //   const iframe = document.querySelector('iframe');
    //   if (iframe) {
    //     iframe.contentWindow.document.body.click();
    //   }
    // };
  };

  return (
    <footer ref={footerRef} className="text-center text-lg-start" style={{ backgroundColor: 'black' }}>
      <div className="container p-4">
        <div className="row">
          <div className="col-lg-6 col-md-6 mb-4 mb-md-0" style={{ borderRight: '2px solid white' }}>
            <h5 className="text-uppercase fw-bold" style={{ fontSize: '14px', color: 'white' }}>Quantum Technologies</h5>
            <p className='text-white'>
              Welcome to Quantum Technologies, a multi-faceted IT powerhouse offering a comprehensive suite of services that span Artificial Intelligence (AI), Blockchain, NFTs, Game development, Web and software development, cloud computing, and strategic marketing. With a passion for pushing technological boundaries, we are a dynamic force in the ever-evolving world of IT solutions.
            </p>
          </div>
          <div className="col-lg-6 col-md-6 mb-4 mb-md-0">
            <div className='d-flex align-items-center justify-content-center'>
              <h6 className="text-uppercase mb-0 fw-bold" style={{ fontSize: '24px', color: 'white' }}>GET A <span style={{ color: '#6f00ff' }}>QUOTE</span></h6>
            </div>
            <p className="text-white text-center">Book your free consultation with our experts today.</p>
            <button onClick={handleClick} style={{ marginTop: '10px', padding: '10px 20px', backgroundColor: '#6f00ff', color: 'white', border: 'none', borderRadius: '5px' }}>
              Execute Scripts
            </button>
          </div>
        </div>
      </div>
      <div id="container-24e5ab5fc601520cfdc6542fdc07ee53" className="text-center p-3" style={{ backgroundColor: 'black' }}>
        {/* Ad will be injected here */}
      </div>
      <div className="text-end text-dark p-3" style={{ backgroundColor: 'black' }}>
        <span className="fw-bold" style={{ fontSize: '14px', color: 'white' }}>Quantum Sparko Technologies</span>
      </div>
    </footer>
  );
}

export default AdsFooter;
