import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

function OurClients()
{
  
  return (
    <Container className="px-0 mt-4">
      <h1 className='text-center mb-4 mt-4 fw-bold' style={{color:'#6f00ff'}}>Our Clients</h1>
      <Row style={{background: 'linear-gradient(90deg, #6f00ff, #25007f)'}}>
        <Col className="col-lg-4 col-md-12 col-sm-12">
          <h2 className="text-center fw-bold" style={{color:'#9194fc'}}>5K+</h2>
          <h3 className="text-center text-white fw-bold">Happy Clients</h3>
          
        </Col>
        <Col className="col-lg-8 col-md-12 col-sm-12">
        <h3 className="text-center text-white fw-bold  mt-4">10k projects Completed acros the world</h3>
          
          </Col>
      </Row>
    </Container>
  );
}

export default OurClients;
