import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
function ContactUs() {
  return (
    <>
   
    <div class="container-fluid" style={{ backgroundColor: '#f8f9fa' }} >
    <h1 className='text-center mb-4 mt-4 fw-bold' style={{color:'#6f00ff'}}>Contact us</h1>
    <div className='row justify-content-center align-item-center'>
       <div className='col-lg-6 col-md-6 col-sm-12'>
  
        <Form >
        <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Full Name</Form.Label>
            <Form.Control type="text" placeholder="Enter name" style={{backgroundColor:'  rgb(235, 235, 235)'}}/>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" placeholder="Enter email" style={{backgroundColor:'  rgb(235, 235, 235)'}} />
          </Form.Group>
          <Form.Label>Select Service</Form.Label>
          <select class="form-select" aria-label="Default select example" style={{backgroundColor:'  rgb(235, 235, 235)'}}>
  <option selected>Open this select menu</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
<div class="mb-3">
  <label for="exampleFormControlTextarea1" class="form-label">Example textarea</label>
  <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" style={{backgroundColor:'  rgb(235, 235, 235)'}}></textarea>
</div>
<div className="text-center mb-4">
          <Button className="btn " type="submit" style={{width:'550px',backgroundColor:'#5d21d2'}}>
            Submit
          </Button>
          </div>
        </Form>
        </div>
        </div>
        </div>
        </>
      );
    }
    


export default ContactUs