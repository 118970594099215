import React from 'react'
import AboutUs from '../AboutUs/AboutUs'
import Services from '../Services/Services'
import OurClients from '../OurClients/OurClients'
import ContactUs from '../ContactUs/ContactUs'
import Team from '../Team/Team'
import Footer from '../Footer/Footer'
import './Home.css'
import Testimonial from '../Testimonial/Testimonial'



function Home() {
  return (
    <>
<div class="Home-style">
<video autoPlay loop muted playsInline className="video-background">
        <source src='/back.mp4' type="video/mp4" />
      </video>
      <div className="content-overlay">
        <div className='row'>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <h1 className='text-center text-dark mt-4 text-white'>
              The only way to<br />build your dream<br />Software
            </h1>
           
          </div>
        </div>
      </div>
</div>
    <AboutUs/>
    <Services/>
    <OurClients/>
    <Team/>
    <Testimonial/>
    <ContactUs/>
    <Footer/>

    </>
  )
}

export default Home