// App.js
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Ads from './Components/Ads/Ads';
import Home from './Components/Home/Home';
import Navbar from './Components/Navbar/Header';

function App()
{
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Ads" element={<Ads />} />
          {/* <Route path="/about" component={About} />
          <Route path="/services" component={Services} />
          <Route path="/team" component={Team} />
          <Route path="/contact" component={Contact} /> */}
          {/* Add more routes for other pages/components */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
