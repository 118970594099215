import React from 'react';
import companyInfo from '../../images/logoname.jpeg';

function AboutUs()
{
  return (
    <div className="container-fluid" style={{ backgroundColor: '#f8f9fa' }}>
      <h1 className="text-center fw-bold" style={{color:'#6f00ff'}}>About Us</h1>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
          <img src={companyInfo} style={{ maxWidth: '100%', height: 'auto', borderRadius: '20px' }} alt="Company Info" />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 d-flex flex-column justify-content-center align-items-center text-center">
          <h1 className="text-dark mb-4">The only way to<br />build your dream<br />Software</h1>
          <p className="mb-4" style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
            At Quantum Spark Technology, our diverse team creates innovative digital solutions. We blend precision and creativity, understanding your unique needs to deliver visually stunning, client-centric experiences.
          </p>
          <p className="mb-4" style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
            Our expertise spans across various industries, ensuring that each project is tailored to meet the specific demands of our clients. We believe in a collaborative approach, working closely with you to bring your vision to life.
          </p>
          <p className="mb-4" style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
            Join us on a journey of innovation and excellence, where your ideas are transformed into reality through cutting-edge technology and creative design.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
