// Components/Ads/Ads.jsx
import React from 'react';
import AdsFooter from '../Ads footer/Footer';
import AdsTeam from '../Ads Team/AdsTeam';
const Ads = () => {
    return (
      <>
    <div>
      <h2>About Page</h2>
      <p>This is the About page content.</p>
            </div>
            <AdsTeam/>
           <AdsFooter/>
            </>
  );
}

export default Ads;
